<script setup>
import { useDisplay } from "vuetify";
import EcosystemCard from "/components/EcosystemCard.vue";

const config = useRuntimeConfig();
const zwapLink = config.public.zwapLink;
const fractionZLink = config.public.fractionZLink;

const { mdAndUp, sm } = useDisplay();
</script>

<template>
  <div
    class="mx-4 mx-sm-8 mx-md-16 bg-grey rounded-xl px-5 px-md-16 py-16 py-sm-8 text-black"
  >
    <VRow class="align-center">
      <VCol>
        <span class="text-ltrenovate text-black text-subtitle-1">[ 02 ]</span>
      </VCol>
      <VCol>
        <span
          class="d-flex justify-end text-uppercase text-no-wrap text-ltrenovate text-black text-subtitle-1"
        >
          [ {{ $t("ecosystem.official_dapps") }} ]
        </span>
      </VCol>
    </VRow>

    <VRow class="my-6 my-md-10">
      <VCol md="8" cols="12">
        <div>
          <h1 class="text-wrap text-h1 text-black">
            {{ $t("ecosystem.join_the_zchains_ecosystem[0]") }}
            <i>
              <span class="text-primary">{{
                $t("ecosystem.join_the_zchains_ecosystem[1]")
              }}</span>
              {{ $t("ecosystem.join_the_zchains_ecosystem[2]") }}
            </i>
          </h1>
        </div>
      </VCol>

      <!-- <VCol md="4" class="d-md-flex align-md-end justify-md-end">
        <div class="d-flex justify-space-between align-md-end mb-md-8 large-screen-underline">
          <div class="text-uppercase text-h6 text-black small-screen-underline text-ltrenovate">
            {{ $t("ecosystem.discover_more") }}
          </div>
          <div class="ml-md-3">
            <VImg :src="imgDiscoverArrow" width="32" />
          </div>
        </div>
      </VCol> -->
    </VRow>

    <div class="pa-0 py-5 ma-0 d-flex flex-column flex-md-row ga-10">
      <div
        class="mx-0 ga-md-10 ga-5 d-flex flex-column flex-sm-row mx-sm-5 mx-md-0"
        :class="[{ 'w-75': mdAndUp }]"
      >
        <div class="d-flex flex-column align-center">
          <div class="d-flex flex-column position-relative card-class ">
            <div class="box position-absolute box-position-tn1" />
            <div class="box position-absolute box-position-tn2" />
            <NuxtLink :to="zwapLink" target="_blank">
              <EcosystemCard
                imgSrc="/images/dapp/Zwap.png"
                :title="$t('ecosystem.zwap')"
                :description="$t('ecosystem.zwap_desc')"
                :badges="[$t('ecosystem.defi'), $t('ecosystem.dex')]"
              />
            </NuxtLink>
             
          </div>
        </div>

        <div class="d-flex flex-column align-center">
          <div class="d-flex flex-column position-relative card-class">
            <div class="box position-absolute box-position-bn1" />
            <div class="box position-absolute box-position-bn2" />
            <NuxtLink :to="fractionZLink" target="_blank">
              <EcosystemCard
                imgSrc="/images/dapp/FractionZ.png"
                :title="$t('ecosystem.fractionz')"
                :description="$t('ecosystem.fractionz_desc')"
                :badges="[$t('ecosystem.rwa')]"
              />
            </NuxtLink>
              
          </div>
        </div>
      </div>
      <div class="d-flex align-md-end ps-md-8" :class="[{ 'w-25': mdAndUp }, { 'w-50': sm }]">
        <span class="text-geomanist text-black text-h6 ml-md-auto pa-1 pa-md-0">{{
          $t("ecosystem.discover_top_dapps_desc")
        }}</span>
      </div>
    </div>

    <VRow class="mt-3">
      <VCol class="text-3xl py-0"> + </VCol>
      <VCol class="d-flex justify-end text-3xl py-0"> + </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.bg-grey {
  background-color: #d9d9d9;
}


.box {
  border: 1px solid red;
  height: 100%;
  width: 100%;
}

.box-position-tn1 {
  top: -15px;
  left: -15px;
}

.box-position-tn2 {
  top: -10px;
  left: -10px;
}

.box-position-bn1 {
  bottom: -15px;
  right: -15px;
}

.box-position-bn2 {
  bottom: -10px;
  right: -10px;
}

@media (max-width: 599px) {
  .card-class {
    width: 100%;
    max-width: 387px;
    min-height: 387px;
  }
}

@media (min-width: 600px) {
  // .small-screen-underline {
  //   position: relative;
  //   display: inline-block;
  // }

  // .small-screen-underline::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0px;
  //   left: 0;
  //   width: 100%;
  //   border-bottom: 2px solid red;
  // }

  // .small-screen-underline::before {
  //   content: "";
  //   position: relative;
  //   bottom: 3px;
  //   left: 0;
  //   width: 100%;
  //   border-bottom: 2px solid red;
  // }

  .card-class{
    min-width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: fit-content;
  }
}

@media (min-width: 960px) {
  // .large-screen-underline {
  //   position: relative;
  //   display: inline-block;
  // }

  // .large-screen-underline::after {
  //   content: "";
  //   position: absolute;
  //   bottom: -12px;
  //   left: 0;
  //   width: 100%;
  //   border-bottom: 2px solid red;
  // }

  // .large-screen-underline::before {
  //   content: "";
  //   position: absolute;
  //   bottom: -16px;
  //   left: 0;
  //   width: 100%;
  //   border-bottom: 2px solid red;
  // }

  .card-class {
    min-width: 100%;
    max-width: 390px;
    min-height: 100%;
    max-height: fit-content;
  }
}
</style>
